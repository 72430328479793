import React, { FC, useEffect, useState } from 'react';
import LinearProgress from '@fv-components/linear-progress';
import FVID from '../FVID';
import { isNullOrUndefined } from '../../util-helpers/common';

const css = require('./Login.module.scss');

const Callback: FC = () => {
  const [name, setName] = useState<string | undefined | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (isNullOrUndefined(code)) {
      window.location.assign('/test');
    }
    FVID.exchangeCodeForToken(code as string).then((data) => {
      FVID.getUserInfo(data.access_token).then((user) => {
        setName(user.name);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);

        if (window.opener) {
          // Send a message back to the parent window
          window.opener.postMessage(JSON.stringify(localStorage));
        }
      });
    });
  }, []);

  return <>
    <div className={css.container}>
      <div className={css.content} style={{ paddingTop: 50 }}>
        <div className={css.fvidLoginBox} style={{ height: 250 }}>
          <img
            alt="Filevine"
            height="45"
            src="assets/fv-logo.svg"
            style={{ marginLeft: 50, marginBottom: 30 }}
          />
          {!name && <div style={{ padding: 30 }}>
            <LinearProgress />
            <br />
            Logging you in...
          </div>}
          {name && <>
            <p>
              You are signed in as
              <b>
                &nbsp;
                {name}
              </b>
            </p>
            <p>You may close this poup</p>
          </>}
        </div>
      </div>
    </div>
  </>;
};

export default Callback;